import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import withAuthentication from "../components/Session/withAuthentication";
import Header from "../components/Header";
import CourseHeader from "../components/CourseHeader";
import { Container, Row, Col } from "../components/Grid";
import Box from "../components/Box";
import PartCard from "../components/PartCard";

import { isValidating, getStats } from "../state/stats/selectors";
import { isCompleted, getAnswererId } from "../state/courseState/selectors";

import { currentUserLocale } from "../services/locale";

import { graphql } from "gatsby";

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: 100vh;
`;

class Dashboard extends React.Component {
  static contextTypes = {
    authUser: PropTypes.object,
    loggedIn: PropTypes.bool
  };

  render() {
    const { data, intl } = this.props;
    const userLocale = currentUserLocale();
    return (
      <BackgroundBox>
        <Header
          breadcrumbs={[
            {
              title: intl.formatMessage({ id: "globals.courseOverview" }),
              path: intl.formatMessage({ id: "routes.LANDING" })
            }
          ]}
        />

        <CourseHeader
          loggedIn={this.context.loggedIn}
          userLocale={userLocale}
          matchingLocale={userLocale === "fr" ? true : false}
        />

        <Container p={[3, 4, 4]}>
          <Row>
            {data.allParts.edges.map(({ node: { frontmatter } }) => (
              <Col
                key={`${frontmatter.part}-${frontmatter.lang}`}
                w={[12 / 12, 6 / 12, 4 / 12]}
                mb={3}
              >
                <PartCard
                  frontmatter={frontmatter}
                  loggedIn={this.context.loggedIn}
                  data={data}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </BackgroundBox>
    );
  }
}

const mapStateToProps = state => {
  return {
    stats: getStats(state),
    validating: isValidating(state),
    completed: isCompleted(state),
    answererId: getAnswererId(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(withAuthentication(injectIntl(Dashboard)));

export const pageQuery = graphql`
  query dashboardContentFr {
    allParts: allMarkdownRemark(
      sort: { fields: [frontmatter___part], order: ASC }
      filter: { frontmatter: { type: { eq: "part" }, lang: { eq: "fr" } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
            lang
            bannerImage {
              publicURL
            }
          }
        }
      }
    }
    allSections: allMarkdownRemark(
      sort: { fields: [frontmatter___section], order: ASC }
      filter: { frontmatter: { type: { eq: "section" }, lang: { eq: "fr" } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
            lang
          }
        }
      }
    }
  }
`;
